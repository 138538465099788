import find from 'lodash/fp/find';
import groupBy from 'lodash/fp/groupBy';
import map from 'lodash/fp/map';
import noop from 'lodash/fp/noop';
import orderBy from 'lodash/fp/orderBy';
import isNull from 'lodash/isNull';
import omitBy from 'lodash/omitBy';
import { compose, withHandlers, withProps } from 'recompose';

import Select from './Select';

const mapObject = map.convert({ cap: false });

const prepareOptions = ({ locationOptions, translations }) => {
  const optionGroups = translations.location_options_groups || translations.locationOptionsGroups;

  return compose(
    mapObject((groupOptions, region) => ({
      label: optionGroups[region] || optionGroups.regions,
      options: groupOptions,
    })),
    groupBy(option => option.region),
    orderBy(['region', 'label'], ['asc', 'asc'])
  )(locationOptions);
};

const findOptionByValue = (options, value) => find(option => option.value === value)(options);

export default compose(
  withProps(props =>
    omitBy(
      {
        classNamePrefix: 'location-select',
        options: prepareOptions(props),
        value: props.value ? findOptionByValue(props.locationOptions, props.value) : null,
        defaultValue: props.defaultValue ? findOptionByValue(props.locationOptions, props.defaultValue) : null,
      },
      isNull
    )
  ),
  withHandlers({
    onChange: props => option => {
      const onChange = props.onChange || noop;
      onChange(option.value);
    },
    onBlur: props => option => {
      const onBlur = props.onBlur || noop;
      onBlur(option.value);
    },
  })
)(Select);
