import BaseIcon from 'components/Elements/Icon';
import styled, { css } from 'styled-components';
import { breakpoints, colors } from 'styles/config';

/* DateRangePickerContainer */

const heroContainerCss = css`
  margin-bottom: 1rem;

  ${breakpoints.tabletLandscapeUp} {
    margin-bottom: 0;
  }
`;

const sideContainerCss = css`
  margin-bottom: 0.5rem;

  ${breakpoints.tabletLandscapeUp} {
    margin-bottom: 0;
  }

  ${breakpoints.desktopUp} {
    margin-bottom: 0.5rem;
  }
`;

const sideStackedContainerCss = css`
  display: block;
  margin-bottom: 0;
`;

export const DateRangePickerContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-basis: 100%;
  justify-content: space-between;
  position: relative;

  ${props => props.theme.isHero && heroContainerCss};
  ${props => props.theme.isSide && sideContainerCss};
  ${props => props.theme.isSideStacked && sideStackedContainerCss};
`;

/*
 * FormRow
 */

const heroFormRowCss = css`
  flex-basis: calc(50% - 0.5rem);

  ${breakpoints.tabletLandscapeUp} {
    min-width: 13rem;
    margin-bottom: 0;
    margin-right: 0.5rem;
  }
`;

const sideFormRowCss = css`
  flex-basis: calc(50% - 0.25rem);

  ${breakpoints.tabletLandscapeUp} {
    min-width: 13rem;
    margin-bottom: 0;
    margin-right: 0.5rem;
  }

  ${breakpoints.desktopUp} {
    min-width: auto;
    margin-right: 0;
  }
`;

const sideStackedFormRowCss = css`
  display: flex;
  align-items: center;
  position: initial;
  width: 100%;
  margin-bottom: 1rem;
`;

export const FormRow = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-around;

  ${props => props.theme.isHero && heroFormRowCss};
  ${props => props.theme.isSide && sideFormRowCss};
  ${props => props.theme.isSideStacked && sideStackedFormRowCss};
`;

// FormItem

const sideStackedFormItemCss = css`
  flex-basis: 55%;
  flex-shrink: 0;
  flex-grow: 0;
  position: relative;
  margin-bottom: 0;
  align-items: center;
`;

export const FormItem = styled.div`
  display: flex;
  align-items: center;
  flex-basis: 60%;
  flex-grow: 1;
  justify-content: space-between;
  background-color: ${colors.white};

  ${props => props.theme.isSideStacked && sideStackedFormItemCss};
`;

// Label

const hiddenCss = css`
  position: absolute;
  left: -3000%;
  pointer-events: none;
  opacity: 0;
`;

const sideStackedLabelCss = css`
  height: 100%;
  flex-grow: 2;
  line-height: 1.6;
`;

export const Label = styled.label`
  display: flex;
  flex-grow: 2;
  align-items: center;
  line-height: 1;
  margin: 0;
  color: ${colors.white};
  font-size: 1.4rem;
  font-weight: 300;

  ${props => props.theme.isSideStacked && sideStackedLabelCss};
  ${props => !props.show && hiddenCss};
`;

// Input

const heroInputCss = css`
  height: 5rem !important;

  ::placeholder {
    color: ${colors.boulder};
    font-size: 1.4rem !important;
  }

  ${breakpoints.tabletLandscapeUp} {
    height: 5rem !important;

    ::placeholder {
      font-size: 1.4rem !important;
    }
  }
`;

const sideInputCss = css`
  height: 5rem !important;
  width: calc(100% - 2.8rem) !important;
  padding-right: 0 !important;

  ::placeholder {
    color: ${colors.boulder};
  }
`;

const sideStackedInputCss = css`
  height: 4rem !important;
  font-size: 1.4rem !important;
  padding: 0.6rem 0.8rem !important;
  padding-left: 0.9rem !important;
  padding-right: 0 !important;
  width: calc(100% - 3.2rem) !important;

  ::placeholder {
    display: none !important;
  }
`;

export const Input = styled.input`
  background: transparent !important;
  background-color: transparent !important;
  color: hsl(0, 0%, 20%) !important;
  font-size: 1.4rem !important;
  font-weight: 300 !important;
  padding: 0.6rem 0 0.6rem 0.8rem !important;
  width: calc(100% - 4rem) !important;
  padding-right: 0 !important;

  ::placeholder {
    font-weight: 300 !important;
  }

  ${props => props.theme.isSide && sideInputCss};
  ${props => props.theme.isHero && heroInputCss};
  ${props => props.theme.isSideStacked && sideStackedInputCss};
`;

// InputIcon

const sideInputIconCss = css`
  font-size: 2.4rem;

  ${breakpoints.desktopUp} {
    min-width: 2.8rem;
    flex-basis: 2.8rem;
    justify-content: flex-start;
  }
`;

const sideStackedInputIconCss = css`
  font-size: 2.2rem;
  width: 3.2rem;
  min-width: 3.2rem;
`;

export const InputIcon = styled(BaseIcon)`
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-width: 4rem;
  flex-basis: 4rem;
  font-size: 2rem;
  color: ${colors.sun};

  ${props => props.theme.isSide && sideInputIconCss};
  ${props => props.theme.isSideStacked && sideStackedInputIconCss};
`;

// NavIcon

export const NavIcon = styled(BaseIcon)`
  color: ${colors.white};
`;

// Clear link

export const ClearLink = styled.a`
  margin-left: 2rem;
  cursor: pointer;
`;
