const calendarLabel = 'Kalender';
const closeDatePicker = 'Schließen';
const focusStartDate = 'Interagieren Sie mit dem Kalender und fügen Sie das Eincheckdatum für Ihre Reise hinzu.';
const clearDate = 'Datum löschen';
const clearDates = 'Daten löschen';
const jumpToPrevMonth = 'Gehe zurück, um zum vorherigen Monat zu wechseln.';
const jumpToNextMonth = 'Vorwärts, um zum nächsten Monat zu wechseln.';
const keyboardShortcuts = 'Tastaturkürzel';
const showKeyboardShortcutsPanel = 'Öffnet das Tastaturkürzelfeld.';
const hideKeyboardShortcutsPanel = 'Schließe das Schnellzugriffsfenster.';
const openThisPanel = 'Öffnet dieses Fenster.';
const enterKey = 'Enter key';
const leftArrowRightArrow = 'Rechte und linke Pfeiltasten';
const upArrowDownArrow = 'Pfeiltasten nach oben und nach unten';
const pageUpPageDown = 'Seite nach oben und Seite nach unten';
const homeEnd = 'Anfangs- und Endtasten';
const escape = 'Escape-Taste';
const questionMark = 'Fragezeichen';
const selectFocusedDate = 'Wählen Sie das Datum im Fokus.';
const moveFocusByOneDay = 'Rückwärts (links) und vorwärts (rechts) um einen Tag.';
const moveFocusByOneWeek = 'Rückwärts (hoch) und vorwärts (runter) um eine Woche.';
const moveFocusByOneMonth = 'Monate wechseln.';
const moveFocustoStartAndEndOfWeek = 'Gehe zum ersten oder letzten Tag einer Woche.';
const returnFocusToInput = 'Zurück zum Eingabefeld für das Datum.';
const keyboardNavigationInstructions = `Drücken Sie die Pfeiltaste nach unten, um mit dem Kalender zu interagieren und
  Wählen Sie ein Datum aus. Drücken Sie die Fragezeichen-Taste, um
  die Tastaturkürzel zum Ändern der Daten zu erhalten.`;

// eslint-disable-next-line camelcase
const chooseAvailableStartDate = ({ date }) => `Wählen Sie ${date} als Ihr Eincheckdatum. Es ist verfügbar.`;

// eslint-disable-next-line camelcase
const chooseAvailableEndDate = ({ date }) => `Wählen Sie ${date} als Ihr Auscheckdatum. Es ist verfügbar.`;
const chooseAvailableDate = ({ date }) => date;
const dateIsUnavailable = ({ date }) => `Nicht verfügbar. ${date}`;

export default {
  calendarLabel,
  closeDatePicker,
  focusStartDate,
  clearDate,
  clearDates,
  jumpToPrevMonth,
  jumpToNextMonth,
  keyboardShortcuts,
  showKeyboardShortcutsPanel,
  hideKeyboardShortcutsPanel,
  openThisPanel,
  enterKey,
  leftArrowRightArrow,
  upArrowDownArrow,
  pageUpPageDown,
  homeEnd,
  escape,
  questionMark,
  selectFocusedDate,
  moveFocusByOneDay,
  moveFocusByOneWeek,
  moveFocusByOneMonth,
  moveFocustoStartAndEndOfWeek,
  returnFocusToInput,
  keyboardNavigationInstructions,

  chooseAvailableStartDate,
  chooseAvailableEndDate,
  dateIsUnavailable,
};

export const DateRangePickerPhrases = {
  calendarLabel,
  closeDatePicker,
  clearDates,
  focusStartDate,
  jumpToPrevMonth,
  jumpToNextMonth,
  keyboardShortcuts,
  showKeyboardShortcutsPanel,
  hideKeyboardShortcutsPanel,
  openThisPanel,
  enterKey,
  leftArrowRightArrow,
  upArrowDownArrow,
  pageUpPageDown,
  homeEnd,
  escape,
  questionMark,
  selectFocusedDate,
  moveFocusByOneDay,
  moveFocusByOneWeek,
  moveFocusByOneMonth,
  moveFocustoStartAndEndOfWeek,
  returnFocusToInput,
  keyboardNavigationInstructions,
  chooseAvailableStartDate,
  chooseAvailableEndDate,
  dateIsUnavailable,
};

export const DateRangePickerInputPhrases = {
  focusStartDate,
  clearDates,
  keyboardNavigationInstructions,
};

export const SingleDatePickerPhrases = {
  calendarLabel,
  closeDatePicker,
  clearDate,
  jumpToPrevMonth,
  jumpToNextMonth,
  keyboardShortcuts,
  showKeyboardShortcutsPanel,
  hideKeyboardShortcutsPanel,
  openThisPanel,
  enterKey,
  leftArrowRightArrow,
  upArrowDownArrow,
  pageUpPageDown,
  homeEnd,
  escape,
  questionMark,
  selectFocusedDate,
  moveFocusByOneDay,
  moveFocusByOneWeek,
  moveFocusByOneMonth,
  moveFocustoStartAndEndOfWeek,
  returnFocusToInput,
  keyboardNavigationInstructions,
  chooseAvailableDate,
  dateIsUnavailable,
};

export const SingleDatePickerInputPhrases = {
  clearDate,
  keyboardNavigationInstructions,
};

export const DayPickerPhrases = {
  calendarLabel,
  jumpToPrevMonth,
  jumpToNextMonth,
  keyboardShortcuts,
  showKeyboardShortcutsPanel,
  hideKeyboardShortcutsPanel,
  openThisPanel,
  enterKey,
  leftArrowRightArrow,
  upArrowDownArrow,
  pageUpPageDown,
  homeEnd,
  escape,
  questionMark,
  selectFocusedDate,
  moveFocusByOneDay,
  moveFocusByOneWeek,
  moveFocusByOneMonth,
  moveFocustoStartAndEndOfWeek,
  returnFocusToInput,
  chooseAvailableStartDate,
  chooseAvailableEndDate,
  chooseAvailableDate,
  dateIsUnavailable,
};

export const DayPickerKeyboardShortcutsPhrases = {
  keyboardShortcuts,
  showKeyboardShortcutsPanel,
  hideKeyboardShortcutsPanel,
  openThisPanel,
  enterKey,
  leftArrowRightArrow,
  upArrowDownArrow,
  pageUpPageDown,
  homeEnd,
  escape,
  questionMark,
  selectFocusedDate,
  moveFocusByOneDay,
  moveFocusByOneWeek,
  moveFocusByOneMonth,
  moveFocustoStartAndEndOfWeek,
  returnFocusToInput,
};

export const DayPickerNavigationPhrases = {
  jumpToPrevMonth,
  jumpToNextMonth,
};

export const CalendarDayPhrases = {
  chooseAvailableDate,
  dateIsUnavailable,
};
