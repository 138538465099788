import BaseIcon from 'components/Elements/Icon';
import styled from 'styled-components';
import { breakpoints, colors } from 'styles/config';

export const Button = styled.button`
  position: absolute;
  top: 2rem;
  right: 2rem;
  height: auto;
  margin-bottom: 3rem;
  background: none;
  z-index: 200;

  ${breakpoints.tabletPortraitUp} {
    margin-bottom: 2rem;
  }
`;

export const Icon = styled(BaseIcon)`
  font-size: 1.8rem;
  color: ${colors.boulder};
`;

export const PortalContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  background: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  transform: translated3d(0, 0, 0);
  transition: opacity 200ms ease-in-out;
  z-index: 2001;
`;

export const PortalBoxContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 3rem;
  background: white;
  overflow: hidden;

  ${breakpoints.tabletLandscapeUp} {
    max-width: 80rem;
    width: auto;
    height: auto;
    padding: 3rem 2rem;
  }
`;
