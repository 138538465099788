import * as hooks from 'hooks';
import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import PortalBox from './PortalBox';
import { PortalContainer } from './styles';

const ScrollLock = ({ children }) => {
  hooks.useLockBodyScroll();

  return <>{children}</>;
};

const Portal = ({ hidePortal, closeButtonVisible, children, isOpen }) => {
  const portalTarget = useRef(document.createElement('div'));

  useEffect(() => {
    portalTarget.current.className = 'portal';
    document.body.appendChild(portalTarget.current);

    return () => {
      document.body.removeChild(portalTarget.current);
    };
  }, []);

  if (!isOpen) {
    return null;
  }

  return createPortal(
    <ScrollLock>
      <PortalContainer onClick={hidePortal}>
        <PortalBox closeButtonVisible={closeButtonVisible} isOpen={isOpen}>
          {children}
        </PortalBox>
      </PortalContainer>
    </ScrollLock>,
    portalTarget.current
  );
};

export default Portal;
