import { addDays, format, isAfter, isBefore, isSameDay } from 'date-fns';

import { BACKEND_DATE_FNS_FORMAT, BACKEND_DATE_FORMAT, END_DATE, START_DATE } from './constants';

const compareDateStrings = (dateString1, dateString2) => {
  const date1 = new Date(dateString1);
  const date2 = new Date(dateString2);

  if (date1 < date2) {
    return -1;
  }

  if (date1 > date2) {
    return 1;
  }

  return 0;
};

const getlastBookable = dates => {
  const sortedDates = dates.sort(compareDateStrings);
  const datesDays = sortedDates.map(date => new Date(date).getTime() / 1000);
  const unixDay = 24 * 60 * 60;

  let i;

  for (i = 1; i < datesDays.length; i += 1) {
    if (datesDays[i] - datesDays[i - 1] !== unixDay) {
      break;
    }
  }

  return sortedDates[i - 1];
};

const getDatesAfter = (dateStrings, startDateString) => {
  const startDate = new Date(startDateString);

  return dateStrings.filter(dateString => {
    const date = new Date(dateString);
    return isSameDay(date, startDate) || isAfter(date, startDate);
  });
};

const getDatesBefore = (dateStrings, endDateString, options = { inclusive: false }) => {
  const endDate = new Date(endDateString);

  const filtered = dateStrings.filter(dateString => {
    const date = new Date(dateString);
    return isSameDay(date, endDate) || isBefore(date, endDate);
  });

  return options.inclusive ? [...filtered, endDateString] : filtered;
};

export const isDayBlocked = (state, props) => {
  const { startDate, endDate, focusedInput } = state;
  let { availableDates } = props;

  if (!availableDates.length) {
    return () => false;
  }

  const startDateString = startDate ? startDate.format(BACKEND_DATE_FORMAT) : null;
  const endDateString = endDate ? endDate.format(BACKEND_DATE_FORMAT) : null;

  const datesAfterStart = getDatesAfter(availableDates, startDateString);
  const datesBeforeEnd = getDatesBefore(availableDates, endDateString);
  const lastBookable = getlastBookable(datesAfterStart.length ? datesAfterStart : availableDates);

  const firstBlocked = format(addDays(new Date(lastBookable), 1), BACKEND_DATE_FNS_FORMAT);
  const datesBetweenStartAndFirstBlocked = getDatesBefore(datesAfterStart, firstBlocked, { inclusive: true });

  return day => {
    const dayFormatted = day.format(BACKEND_DATE_FORMAT);

    if (!(availableDates && availableDates.length)) {
      return false;
    }

    /* WHEN START SELECTED BUT NO END SELECTED */
    if (startDate && !endDate) {
      /* WHEN END DATE INPUT FOCUSED */
      if (focusedInput === END_DATE) {
        availableDates = datesBetweenStartAndFirstBlocked;
        /* WHEN START DATE INPUT FOCUSED */
      } else if (focusedInput === START_DATE) {
        availableDates = [...datesAfterStart, firstBlocked];
      }
    }

    /* WHEN START SELECTED AND END SELECTED */
    if (startDate && endDate) {
      /* WHEN END DATE INPUT FOCUSED */
      if (focusedInput === END_DATE) {
        availableDates = datesBetweenStartAndFirstBlocked;
      }

      /* WHEN END DATE IS THE SAME AS FIRST BLOCKED */
      if (endDate.format(BACKEND_DATE_FORMAT) === firstBlocked) {
        availableDates = [...availableDates, firstBlocked];
      }
    }

    /* WHEN NO START SELECTED BUT END SELECTED */
    if (!startDate && endDate) {
      availableDates = datesBeforeEnd;
    }

    return !availableDates.some(availableDate => availableDate === dayFormatted);
  };
};
