import { branch, renderNothing } from 'recompose';

import { Button, Icon } from './styles';

const CloseButton = ({ hidePortal }) => (
  <Button onClick={hidePortal}>
    <Icon name="close" />
  </Button>
);

export default branch(props => !props.visible, renderNothing)(CloseButton);
