import 'moment/locale/pl';
import 'moment/locale/en-in';
import 'moment/locale/de';

import isNull from 'lodash/isNull';
import noop from 'lodash/noop';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { DayPickerRangeController, toMomentObject } from 'react-dates';
import { ThemeProvider } from 'styled-components';

import {
  DATE_FORMAT,
  END_DATE,
  END_DATE_INPUT_NAME,
  HORIZONTAL_ORIENTATION,
  START_DATE,
  START_DATE_INPUT_NAME,
  VERTICAL_ORIENTATION,
} from './constants';
import { isDayBlocked } from './helpers';
import phrases from './phrases';
import Portal from './Portal';
import { ClearLink, DateRangePickerContainer, FormItem, FormRow, Input, InputIcon, Label, NavIcon } from './styles';

const isOutsideRange = day => day.isBefore(moment().subtract(1, 'days'));

const orientationRelatedProps = isDatePickerHorizontal =>
  omitBy(
    {
      orientation: isDatePickerHorizontal ? HORIZONTAL_ORIENTATION : VERTICAL_ORIENTATION,
      navNext: isDatePickerHorizontal ? <NavIcon name="arrow-right" /> : null,
      navPrev: isDatePickerHorizontal ? <NavIcon name="arrow-left" /> : null,
      daySize: isDatePickerHorizontal ? 50 : null,
    },
    isNull
  );

class DateRangePicker extends Component {
  constructor(props) {
    super(props);
    const { initialStartDate, initialEndDate, language } = props;

    moment.locale(language);

    const startDate = toMomentObject(initialStartDate, DATE_FORMAT);
    const endDate = toMomentObject(initialEndDate, DATE_FORMAT);

    this.state = {
      isOpen: false,
      focusedInput: startDate ? START_DATE : null,
      startDate,
      endDate,
    };
  }

  setOnInputChangeHandler = inputName => value => {
    this.setState({ [inputName]: value }, () => {
      let formInputName;

      switch (inputName) {
        case START_DATE:
          formInputName = START_DATE_INPUT_NAME;
          break;
        case END_DATE:
          if (!this.getDateValue(START_DATE).length) {
            formInputName = START_DATE_INPUT_NAME;
          } else {
            formInputName = END_DATE_INPUT_NAME;
          }
          break;
        default:
          formInputName = END_DATE_INPUT_NAME;
      }

      this.props.change(formInputName, value);
    });
  };

  handleOnDatesChange = dates => {
    const { startDate, endDate } = dates;
    const { change, onDatesChange } = this.props;

    if (startDate && startDate !== this.state.startDate) {
      this.setState({ startDate, endDate: null }, () => {
        change(START_DATE_INPUT_NAME, startDate && startDate.format(DATE_FORMAT));
        change(END_DATE_INPUT_NAME, null);

        this.setState({ focusedInput: END_DATE });
      });
    } else {
      this.setState({ startDate, endDate }, () => {
        change(START_DATE_INPUT_NAME, startDate && startDate.format(DATE_FORMAT));
        change(END_DATE_INPUT_NAME, endDate && endDate.format(DATE_FORMAT));

        if (this.state.startDate && this.state.endDate) {
          this.setState({ isOpen: false }, () => {
            onDatesChange();
          });
        }
      });
    }
  };

  handleOnFocusChange = focusedInput => {
    const inputToSet = !focusedInput ? START_DATE : focusedInput;
    this.setState({ focusedInput: inputToSet });
  };

  handleOnInputFocus = event => {
    event.stopPropagation();
    event.target.blur();
    let inputName;

    switch (event.target.name) {
      case START_DATE_INPUT_NAME:
        inputName = START_DATE;
        break;
      case END_DATE_INPUT_NAME:
        if (!this.getDateValue(START_DATE).length) {
          inputName = START_DATE;
        } else {
          inputName = END_DATE;
        }
        break;
      default:
        inputName = END_DATE;
    }

    this.setState({ focusedInput: inputName, isOpen: true });
  };

  hidePortal = event => {
    event.stopPropagation();
    this.setState({ isOpen: false, focusedInput: null });
  };

  handleClearClick = event => {
    event.preventDefault();
    const { change } = this.props;
    const startDate = null;
    const endDate = null;

    this.setState({ startDate, endDate }, () => {
      change(START_DATE_INPUT_NAME, startDate);
      change(END_DATE_INPUT_NAME, endDate);
      this.setState({ isOpen: true, focusedInput: START_DATE });
    });
  };

  renderCalendarInfo = () => <ClearLink onClick={this.handleClearClick}>{this.props.translations.clear}</ClearLink>;

  getDateValue = dateName => {
    const date = this.state[dateName];
    return date ? date.format(DATE_FORMAT) : '';
  };

  render() {
    const { focusedInput, startDate, endDate, isOpen } = this.state;
    const { inputLayout, language, showLabels, showPlaceholders, startDatePlaceholderText, endDatePlaceholderText } =
      this.props;

    const theme = {
      isHero: inputLayout === 'hero',
      isHorizontal: inputLayout === 'horizontal',
      isSide: inputLayout === 'side',
      isSideStacked: inputLayout === 'sideStacked',
    };

    const props = omit(this.props, [
      'id',
      'availableDates',
      'change',
      'displayFormat',
      'endDatePlaceholderText',
      'initialEndDate',
      'initialStartDate',
      'inputLayout',
      'language',
      'onDatesChange',
      'showLabels',
      'showPlaceholders',
      'startDatePlaceholderText',
      'translations',
    ]);

    const isDatePickerHorizontal =
      typeof window !== 'undefined' ? window.matchMedia('(min-width: 900px)').matches : true;

    return (
      <ThemeProvider theme={theme}>
        <DateRangePickerContainer id={this.props.id}>
          <FormRow>
            <Label show={showLabels}>{`${startDatePlaceholderText}:`}</Label>
            <FormItem>
              <Input
                name={START_DATE_INPUT_NAME}
                placeholder={showPlaceholders ? startDatePlaceholderText : ''}
                type="text"
                value={this.getDateValue(START_DATE)}
                onChange={this.setOnInputChangeHandler(START_DATE)}
                onFocus={this.handleOnInputFocus}
              />
              <InputIcon name="from" />
            </FormItem>
          </FormRow>
          <FormRow>
            <Label show={showLabels}>{`${endDatePlaceholderText}:`}</Label>
            <FormItem>
              <Input
                name={END_DATE_INPUT_NAME}
                placeholder={showPlaceholders ? endDatePlaceholderText : ''}
                type="text"
                value={this.getDateValue(END_DATE)}
                onChange={this.setOnInputChangeHandler(END_DATE)}
                onFocus={this.handleOnInputFocus}
              />
              <InputIcon name="to" />
            </FormItem>
          </FormRow>
          <Portal
            closeButtonVisible={!isDatePickerHorizontal}
            hidePortal={this.hidePortal}
            inProp={!!isOpen}
            isOpen={isOpen}
          >
            <DayPickerRangeController
              endDate={endDate || null}
              focusedInput={focusedInput}
              isDayBlocked={isDayBlocked(this.state, this.props)}
              isOutsideRange={isOutsideRange}
              minimumNights={2}
              phrases={phrases[language]}
              renderCalendarInfo={this.renderCalendarInfo}
              startDate={startDate || null}
              withPortal={!isDatePickerHorizontal}
              isFocused
              onDatesChange={this.handleOnDatesChange}
              onFocusChange={this.handleOnFocusChange}
              {...orientationRelatedProps(isDatePickerHorizontal)}
              {...props}
            />
          </Portal>
        </DateRangePickerContainer>
      </ThemeProvider>
    );
  }
}

DateRangePicker.propTypes = {
  id: PropTypes.string,
  availableDates: PropTypes.array,
  change: PropTypes.func,
  displayFormat: PropTypes.string,
  inputLayout: PropTypes.oneOf(['hero', 'side', 'horizontal', 'sideStacked']),
  monthFormat: PropTypes.string,
  numberOfMonths: PropTypes.number,
  onDatesChange: PropTypes.func,
  showLabels: PropTypes.bool,
  showPlaceholders: PropTypes.bool,
};

DateRangePicker.defaultProps = {
  id: '',
  availableDates: [],
  change: noop,
  displayFormat: DATE_FORMAT,
  inputLayout: 'hero',
  monthFormat: 'MMMM YYYY',
  numberOfMonths: 2,
  onDatesChange: noop,
  showLabels: false,
  showPlaceholders: true,
};

export default DateRangePicker;
