const calendarLabel = 'Kalendarz';
const closeDatePicker = 'Zamknij';
const focusStartDate = 'Użyj kalendarza i wybierz datę przyjazdu.';
const clearDate = 'Wyczyść datę';
const clearDates = 'Wyczyść daty';
const jumpToPrevMonth = 'Nawiguj do tyłu, aby przełączyć na poprzedni miesiąc';
const jumpToNextMonth = 'Nawiguj do przodu, aby przełączyć na następny miesiąc';
const keyboardShortcuts = 'Skróty klawiaturowe';
const showKeyboardShortcutsPanel = 'Otwórz panel ze skrótami klawiaturowymi.';
const hideKeyboardShortcutsPanel = 'Zamknij panel ze skrótami.';
const openThisPanel = 'Otwórz ten panel.';
const enterKey = 'Klawisz Enter';
const leftArrowRightArrow = 'Klawisze strzałek w lewo i prawo';
const upArrowDownArrow = 'Klawisze strzałek w górę i dół';
const pageUpPageDown = 'Klawisze Page Up i Page Down';
const homeEnd = 'Klawisze Home i End';
const escape = 'Klawisz Escape';
const questionMark = 'Znak zapytania';
const selectFocusedDate = 'Wybierz zaznaczoną datę .';
const moveFocusByOneDay = 'Nawiguj do tyłu (w lewo) lub do przodu (w prawo) o jeden dzień.';
const moveFocusByOneWeek = 'Nawiguj do tyłu (w górę) lub do przodu (w dół) o jeden tydzień.';
const moveFocusByOneMonth = 'Przełącz miesiące.';
const moveFocustoStartAndEndOfWeek = 'Idź do pierwszego lub ostatniego dnia tygodnia.';
const returnFocusToInput = 'Wróć do pola daty.';
const keyboardNavigationInstructions = `Naciśnij strzałkę w dół, aby wybrać datę, używając kalendarza. Naciśnij
  przycisk ze znakiem zapytania, aby przejść do panelu ze skrótami klawiaturowymi do zmian dat`;

// eslint-disable-next-line camelcase
const chooseAvailableStartDate = ({ date }) => `Wybierz ${date} jako datę przyjazdu. Data jest dostępna.`;

// eslint-disable-next-line camelcase
const chooseAvailableEndDate = ({ date }) => `Wybierz ${date} jako datę wyjazdu. Data jest dostępna.`;
const chooseAvailableDate = ({ date }) => date;
const dateIsUnavailable = ({ date }) => `Data jest niedostępna. ${date}`;

export default {
  calendarLabel,
  closeDatePicker,
  focusStartDate,
  clearDate,
  clearDates,
  jumpToPrevMonth,
  jumpToNextMonth,
  keyboardShortcuts,
  showKeyboardShortcutsPanel,
  hideKeyboardShortcutsPanel,
  openThisPanel,
  enterKey,
  leftArrowRightArrow,
  upArrowDownArrow,
  pageUpPageDown,
  homeEnd,
  escape,
  questionMark,
  selectFocusedDate,
  moveFocusByOneDay,
  moveFocusByOneWeek,
  moveFocusByOneMonth,
  moveFocustoStartAndEndOfWeek,
  returnFocusToInput,
  keyboardNavigationInstructions,

  chooseAvailableStartDate,
  chooseAvailableEndDate,
  dateIsUnavailable,
};

export const DateRangePickerPhrases = {
  calendarLabel,
  closeDatePicker,
  clearDates,
  focusStartDate,
  jumpToPrevMonth,
  jumpToNextMonth,
  keyboardShortcuts,
  showKeyboardShortcutsPanel,
  hideKeyboardShortcutsPanel,
  openThisPanel,
  enterKey,
  leftArrowRightArrow,
  upArrowDownArrow,
  pageUpPageDown,
  homeEnd,
  escape,
  questionMark,
  selectFocusedDate,
  moveFocusByOneDay,
  moveFocusByOneWeek,
  moveFocusByOneMonth,
  moveFocustoStartAndEndOfWeek,
  returnFocusToInput,
  keyboardNavigationInstructions,
  chooseAvailableStartDate,
  chooseAvailableEndDate,
  dateIsUnavailable,
};

export const DateRangePickerInputPhrases = {
  focusStartDate,
  clearDates,
  keyboardNavigationInstructions,
};

export const SingleDatePickerPhrases = {
  calendarLabel,
  closeDatePicker,
  clearDate,
  jumpToPrevMonth,
  jumpToNextMonth,
  keyboardShortcuts,
  showKeyboardShortcutsPanel,
  hideKeyboardShortcutsPanel,
  openThisPanel,
  enterKey,
  leftArrowRightArrow,
  upArrowDownArrow,
  pageUpPageDown,
  homeEnd,
  escape,
  questionMark,
  selectFocusedDate,
  moveFocusByOneDay,
  moveFocusByOneWeek,
  moveFocusByOneMonth,
  moveFocustoStartAndEndOfWeek,
  returnFocusToInput,
  keyboardNavigationInstructions,
  chooseAvailableDate,
  dateIsUnavailable,
};

export const SingleDatePickerInputPhrases = {
  clearDate,
  keyboardNavigationInstructions,
};

export const DayPickerPhrases = {
  calendarLabel,
  jumpToPrevMonth,
  jumpToNextMonth,
  keyboardShortcuts,
  showKeyboardShortcutsPanel,
  hideKeyboardShortcutsPanel,
  openThisPanel,
  enterKey,
  leftArrowRightArrow,
  upArrowDownArrow,
  pageUpPageDown,
  homeEnd,
  escape,
  questionMark,
  selectFocusedDate,
  moveFocusByOneDay,
  moveFocusByOneWeek,
  moveFocusByOneMonth,
  moveFocustoStartAndEndOfWeek,
  returnFocusToInput,
  chooseAvailableStartDate,
  chooseAvailableEndDate,
  chooseAvailableDate,
  dateIsUnavailable,
};

export const DayPickerKeyboardShortcutsPhrases = {
  keyboardShortcuts,
  showKeyboardShortcutsPanel,
  hideKeyboardShortcutsPanel,
  openThisPanel,
  enterKey,
  leftArrowRightArrow,
  upArrowDownArrow,
  pageUpPageDown,
  homeEnd,
  escape,
  questionMark,
  selectFocusedDate,
  moveFocusByOneDay,
  moveFocusByOneWeek,
  moveFocusByOneMonth,
  moveFocustoStartAndEndOfWeek,
  returnFocusToInput,
};

export const DayPickerNavigationPhrases = {
  jumpToPrevMonth,
  jumpToNextMonth,
};

export const CalendarDayPhrases = {
  chooseAvailableDate,
  dateIsUnavailable,
};
