import ReactSelect from 'react-select';
import { colors } from 'styles/config';

const controlStyles = {
  height: '5rem',
  border: 0,
  borderRadius: 0,
  fontWeight: 300,
  outline: 'none',
  ':hover': {
    border: 'none',
    boxShadow: 'none',
  },
};

const valueContainerStyles = {
  display: 'flex',
  alignTtems: 'center',
  padding: '.6rem 4rem .6rem .8rem',
  overflow: 'hidden',
  height: '100%',
  color: colors.boulder,
  fontSize: '1.4rem',
  fontWeight: 300,
  fontFamily: 'Montserrat',
  letterSpacing: '0.02rem',
};

const menuStyles = {
  width: '100%',
  background: colors.white,
  borderRadius: 0,
  marginTop: '.2rem',
  border: `.1rem solid ${colors.concrete}`,
  boxShadow: `0 0.1rem 0.1rem 0 rgba(${colors.grayRGB}, 0.5)`,
  zIndex: 11,
};

const singleValueStyles = {
  position: 'static',
  transform: 'none',
  fontSize: '1.4rem',
  fontWeight: 300,
  fontFamily: 'Montserrat',
};

const placeholderStyles = {
  position: 'static',
  top: 0,
  transform: 'none',
  fontSize: '1.4rem',
  fontWeight: 300,
  fontFamily: 'Montserrat',
  color: colors.boulder,
};

const getOptionBackgroundColor = state => {
  if (state.isSelected) {
    return colors.sun;
  }

  if (state.isFocused) {
    return colors.whitesmoke;
  }

  return colors.white;
};

const optionStyles = (styles, state) => ({
  ...styles,
  backgroundColor: getOptionBackgroundColor(state),
});

const customStyles = {
  input: styles => ({ ...styles, backgroundColor: 'none' }),
  control: styles => ({ ...styles, ...controlStyles }),
  placeholder: styles => ({ ...styles, ...placeholderStyles }),
  indicatorsContainer: styles => ({ ...styles, display: 'none' }),
  valueContainer: styles => ({ ...styles, ...valueContainerStyles }),
  menu: styles => ({ ...styles, ...menuStyles }),
  singleValue: styles => ({ ...styles, ...singleValueStyles }),
  groupHeading: styles => ({ ...styles, color: colors.mineShaft }),
  option: optionStyles,
};

const Select = props => {
  const { options, name, value, onChange } = props;

  return (
    <ReactSelect
      id={name}
      isClearable={false}
      isSearchable={false}
      name={name}
      options={options}
      value={value}
      blurInputOnSelect
      onChange={onChange}
      {...props}
      optionClassName="needsclick"
      styles={{ ...customStyles, ...props.styles }}
    />
  );
};

export default Select;
